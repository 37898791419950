<template>
  <v-dialog v-model="open" persistent max-width="500">
    <v-card>
      <v-card-title>
        {{ dialog.type === "create" ? "建立櫃檯帳表" : "編輯櫃檯帳表" }}
      </v-card-title>
      <v-card-text>
        <div class="mx-auto">
          <v-row>
            <v-col cols="12">
              <v-select
                label="店點"
                v-model="payload.branch"
                :items="branchOptions"
                :readonly="dialog.type === 'update'"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="payload.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="payload.date"
                    v-bind="attrs"
                    v-on="on"
                    label="日期"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="payload.date"
                  no-title
                  scrollable
                  locale="zh-tw"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    取消
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(payload.date)"
                  >
                    確定
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.number="payload.pettyCash"
                label="零用金"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>支出收入明細</v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="mt-3"
                v-model.number="payload.cash"
                label="打烊現金"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="mt-3"
                v-model.number="payload.expenditure"
                label="支出"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="mt-3"
                v-model.number="payload.income"
                label="收入"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="mt-3"
                v-model.number="payload.sum"
                label="總結"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                class="mt-3"
                v-model="payload.comment"
                label="備註"
                outlined
                dense
                hide-details
                clearable
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">一番賞收入</v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                class="mt-3"
                v-model.number="payload.bandaiCash"
                label="現金"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                class="mt-3"
                v-model.number="payload.bandaiRemit"
                label="匯款"
                outlined
                dense
                hide-details
                clearable
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="mt-3"
                :value="payload.bandaiCash + payload.bandaiRemit"
                label="現金＋匯款共計"
                outlined
                dense
                hide-details
                readonly
                filled
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="clear" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update(payload)"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import util from "@/mixins/util";

export default {
  name: "IncomeStatementDialogForm",
  props: ["value", "dialog", "incomeStatement"],
  mixins: [util],
  data: () => ({
    amount: null,
    payload: {},
    menu: false,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    branchOptions() {
      return [
        { text: "請選擇", value: "", disabled: true },
        ...this.accessBranch,
      ];
    },
  },
  created() {
    if (this.incomeStatement) {
      this.payload = _.cloneDeep(this.incomeStatement);
      return;
    }
    this.resetPayload();
  },
  methods: {
    async create() {
      const payload = _.cloneDeep(this.payload);

      for (let key in payload) {
        // if (key === "comment") continue;
        if (!payload[key] && payload[key] !== 0) {
          return this.$toast.error("請填寫所有欄位");
        }
      }

      try {
        await this.axios.post(`/incomeStatement`, {
          ...payload,
          user: this.$store.state.user.帳號,
        });
        this.$toast.success("建立成功");
      } catch (error) {
        this.$toast.error("建立失敗");
      }

      this.$emit("load");
      this.clear();
    },
    async update(item) {
      const { _id, ...payload } = item;
      await this.axios.put(`/incomeStatement/${_id}`, payload);
      this.$toast.success("建立成功");
      this.$emit("load");
      this.clear();
    },
    clear() {
      this.$emit("input", false);
    },
    resetPayload() {
      this.payload = {
        branch: "",
        date: dayjs().format("YYYY-MM-DD"),
        pettyCash: 0,
        user: this.$store.state.user.帳號,
        cash: 0,
        expenditure: 0,
        income: 0,
        sum: 0,
        comment: "",
        bandaiCash: 0,
        bandaiRemit: 0,
      };
    },
  },
};
</script>
