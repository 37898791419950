<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      當日櫃檯帳表

      <!-- <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        建立帳表
      </v-btn> -->
    </h3>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="todayIncome"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="success"
              small
              @click="openDialog('update', item)"
              v-if="item._id"
            >
              編輯
            </v-btn>
            <v-btn
              v-else
              color="primary"
              small
              @click="
                openDialog('create', {
                  ...payload,
                  branch: item.branch,
                  date: item.date,
                  user: $store.state.user.帳號,
                })
              "
            >
              建立
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCreateIncomeStatement
      v-if="dialog.open"
      v-model="dialog.open"
      :incomeStatement="payload"
      :dialog="dialog"
      @load="load"
    />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import DialogCreateIncomeStatement from "@/components/dashboard/income-statement/DialogForm.vue";

export default {
  name: "IncomeStatement",
  mixins: [util],
  components: { DialogCreateIncomeStatement },
  data: () => ({
    data: [],
    menu: false,
    payload: {
      date: dayjs().format("YYYY-MM-DD"),
      branch: "",
      pettyCash: 0,
      user: "",
      cash: 0,
      expenditure: 0,
      income: 0,
      sum: 0,
      comment: "",
      bandaiCash: 0,
      bandaiRemit: 0,
    },
    dialog: { type: "create", open: false },
    filterQuery: {
      branch: "",
    },
    selectBranch: "",
    headers: [
      { text: "店點", value: "branch", align: "center", sortable: false },
      { text: "日期", value: "date", align: "center", sortable: false },
      { text: "最近編輯", value: "user", align: "center", sortable: false },
      { text: "操作", value: "actions", align: "center", sortable: false },
    ],
  }),
  computed: {
    _: () => _,
    branches() {
      switch (this.$store.state.user.角色) {
        case "主管":
        case "店長":
          return ["文賢", "崇善", "奇美", "友愛"];
        case "友愛":
          return ["友愛"];
        case "店員":
        case "採購":
        default:
          return ["文賢", "崇善", "奇美"];
      }
    },
    branchOptions() {
      return [
        { text: "請選擇", value: "", disabled: true },
        ...this.accessBranch,
      ];
    },
    todayIncome() {
      const today = dayjs().format("YYYY-MM-DD");
      const todayAll = _.groupBy(this.data, "date")[today];
      const branchMap = _.groupBy(todayAll, "branch");
      return this.branches.map((branch) => {
        return branchMap[branch]
          ? branchMap[branch][0]
          : { branch, date: today };
      });
    },
  },
  async created() {
    this.resetPayload();
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();

      await this.getIncomeStatement();

      this.$vloading.hide();
    },
    async getIncomeStatement() {
      let { data } = await this.axios.get(`/incomeStatement`);
      this.data = data;
    },
    async create() {
      let payload = _.cloneDeep(this.payload);
      console.log("create", payload);

      for (let key in payload) {
        // if (key === "comment") continue;
        if (!payload[key] && payload[key] !== 0) {
          return this.$toast.error("請填寫所有欄位");
        }
      }

      try {
        await this.axios.post(`/incomeStatement`, payload);
        this.$toast.success("建立成功");
      } catch (error) {
        this.$toast.error("建立失敗");
      }

      this.load();
      this.clear();
    },
    async update(item) {
      const { _id, ...payload } = item;
      await this.axios.put(`/incomeStatement/${_id}`, payload);
      this.$toast.success("建立成功");
      this.load();
      this.clear();
    },
    clear() {
      this.dialog.open = false;
      this.resetPayload();
    },
    openDialog(type, payload) {
      if (payload) this.payload = _.cloneDeep(payload);
      else this.resetPayload();
      this.dialog.type = type;
      this.dialog.open = true;
    },
    resetPayload() {
      this.payload = {
        date: dayjs().format("YYYY-MM-DD"),
        branch: "",
        pettyCash: 0,
        user: this.$store.state.user.帳號,
        cash: 0,
        expenditure: 0,
        income: 0,
        sum: 0,
        comment: "",
        bandaiCash: 0,
        bandaiRemit: 0,
      };
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
